import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const sentryDsn = 'https://64728eeff15e494a9aa7015a772e65c6@o1084220.ingest.sentry.io/6153302';
Sentry.init({
  dsn: sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampler: context => {
    if (context.exception) return 1;
    return 0; // block all but exceptions
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
