import './App.css';

function App() {

  const crash2 = () => {
    console.log(window.a.notExisting2);
  }

  return (
    <div className="App">
      <h1>Hello App</h1>
      <div>
        <button onClick={crash2}>Crash 2</button>
      </div>
    </div>
  );
}

export default App;
